.container p {
  text-align: center;
  position: relative;
}

.sort {
  /* color: var(--white); */
  position: fixed;
  bottom: 60px;
  right: 30px;
  background: rgba(255, 255, 255, .9);
  border-radius: 10px;
  /* z-index: 10000; */
}

.fab {
  /* z-index: 10000; */
}

.MuiFormLabel-root {
  color: aliceblue;
}
